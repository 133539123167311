import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import {
  postApi,
  messages,
} from "../helpers/apiStructure";
import { baseUrl } from "../const";

//dashboard
export const getDashboard = createAsyncThunk("getDashboard", async (body) => {
  const result = await postApi(`${baseUrl}/api/dashboard`, "get", body);
  return result;
});
//payment gateway
export const getPaymentOptions = createAsyncThunk("getPaymentOptions", async (body) => {
  const result = await postApi(`${baseUrl}/api/payment/option`, "get", body);
  return result;
});
export const updatePaymentOptions = createAsyncThunk("updatePaymentOptions", async (body) => {
  const result = await postApi(`${baseUrl}/api/payment/option/${body?.id}`,"put",body);
  await messages(result?.message, result?.status);
  return result;
});
export const addPaymentOptions = createAsyncThunk("addPaymentOptions", async (body) => {
  const result = await postApi(`${baseUrl}/api/payment/option`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getPayOut = createAsyncThunk("getPayOut", async (body) => {
  const result = await postApi(`${baseUrl}/api/payout`, "get", body);
  return result;
});
export const updatePayOut = createAsyncThunk("updatePayOut", async (body) => {
  const result = await postApi(`${baseUrl}/api/payout/${body?.id}`,"put",body);
  await messages(result?.message, result?.status);
  return result;
});
export const addPayOut = createAsyncThunk("addPayOut", async (body) => {
  const result = await postApi(`${baseUrl}/api/payout`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
// Game Mgt
export const getGames = createAsyncThunk("getGames", async (body) => {
  const result = await postApi(`${baseUrl}/api/games`, "get", body);
  return result;
});
export const gameUpdate = createAsyncThunk("gameUpdate", async (body) => {
  const result = await postApi(`${baseUrl}/api/games/update/${body?.id}`,"put",body);
  await messages(result?.message, result?.status);
  return result;
});
export const gameHistory = createAsyncThunk("gameHistory", async (body) => {
  const result = await postApi(`${baseUrl}/api/gamehistory/numberpdgamelist`, "post", body);
  return result;
});
export const getUserGameHistory = createAsyncThunk("getUserGameHistory", async (id) => {
  const result = await postApi(`${baseUrl}/api/users/gamehistory/${id}`, "get");
  return result;
});
export const getGameTransactions = createAsyncThunk("getGameTransactions", async (id) => {
  const result = await postApi(`${baseUrl}/api/gamehistory/transactions/${id}`, "get");
  return result;
});
//Red & Black
export const redBlackGameHistory = createAsyncThunk("redBlackGameHistory", async (body) => {
  const result = await postApi(`${baseUrl}/api/blackred/gamehis`, "post", body);
  return result;
});
export const redBlackCurrentGame = createAsyncThunk("redBlackCurrentGame", async (body) => {
  const result = await postApi(`${baseUrl}/api/blackred/currentgame`, "post", body);
  return result;
});
//Fund Transfer 
export const fundTransfer = createAsyncThunk("fundTransfer", async (body) => {
  const result = await postApi(`${baseUrl}/api/payment/fundtransfer`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
//Color Game
export const colorGameHistory = createAsyncThunk("colorGameHistory", async (body) => {
  const result = await postApi(`${baseUrl}/api/colorgame/gamehis`, "post", body);
  return result;
});
export const colorCurrentGame = createAsyncThunk("colorCurrentGame", async (body) => {
  const result = await postApi(`${baseUrl}/api/colorgame/currentgame`, "post", body);
  return result;
});
//tambola game
export const tambolaFutureGames = createAsyncThunk("tambolaFutureGames", async (body) => {
  const result = await postApi(`${baseUrl}/api/tambolaGameHistory/get-live-future`, "post", body);
  return result;
});
export const updateTambolaFutureGame = createAsyncThunk("updateTambolaFutureGame", async (body) => {
  const result = await postApi(`${baseUrl}/api/tambolaGameHistory/${body?.id}`,"put",body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateSubadminTambolaFutureGame = createAsyncThunk("updateSubadminTambolaFutureGame", async (body) => {
  const result = await postApi(`${baseUrl}/api/tambolaGameHistory/gameupdatesubadmin/${body?.id}`,"put",body);
  await messages(result?.message, result?.status);
  return result;
});
export const tambolaGameByID = createAsyncThunk("tambolaGameByID", async (body) => {
  const result = await postApi(`${baseUrl}/api/tambolaGameHistory/game-by-id`, "post", body);
  return result;
});
export const tambolaDrawJackpotNumber = createAsyncThunk("tambolaDrawJackpotNumber", async (body) => {
  const result = await postApi(`${baseUrl}/api/tambolaGameHistory/jackpot-number`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const tambolaGameHisTicketByGame = createAsyncThunk("tambolaGameHisTicketByGame", async (body) => {
  const result = await postApi(`${baseUrl}/api/tambolaGameHistory/tickets-by-gameid`, "post", body);
  return result;
});
export const tambolaDrawNumber = createAsyncThunk("tambolaDrawNumber", async (body) => {
  const result = await postApi(`${baseUrl}/api/tambolaGameHistory/draw-number`, "post", body);
  return result;
});
export const tambolaBooking = createAsyncThunk("tambolaBooking", async (body) => {
  const result = await postApi(`${baseUrl}/api/bettings/tambolabooking`, "post", body);
  return result;
});
export const multipleTambolaTicketBooking = createAsyncThunk("multipleTambolaTicketBooking", async (body) => {
  const result = await postApi(`${baseUrl}/api/tambolaGameHistory/multipleset-booking`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const multipleTambolaTicketBookingFromAgent = createAsyncThunk("multipleTambolaTicketBookingFromAgent", async (body) => {
  const result = await postApi(`${baseUrl}/api/tambolaGameHistory/multipleticket-subagent`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getTambolaReward = createAsyncThunk("getTambolaReward", async (body) => {
  const result = await postApi(`${baseUrl}/api/tambolaReward`, "get", body);
  return result;
});
export const addTambolaGameByAdmin = createAsyncThunk("addTambolaGameByAdmin", async (body) => {
  const result = await postApi(`${baseUrl}/api/tambolaGameHistory`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const addTambolaGameBySubAdmin = createAsyncThunk("addTambolaGameBySubAdmin", async (body) => {
  const result = await postApi(`${baseUrl}/api/tambolaGameHistory/creategamebysubAdmin`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const upcommingGames = createAsyncThunk("upcommingGames", async (body) => {
  const result = await postApi(`${baseUrl}/api/tambolaGameHistory/subAdminUpcomminglist`, "post", body);
  return result;
});
//Bots
export const getBoats = createAsyncThunk("getBoats", async (body) => {
  const result = await postApi(`${baseUrl}/api/boats`, "get", body);
  return result;
});
//Number pd 
export const numberPdGame = createAsyncThunk("numberPdGame", async (body) => {
  const result = await postApi(`${baseUrl}/api/numberpd/gamepage`, "post", body);
  return result;
});
export const getNumberpdType = createAsyncThunk("getNumberpdType", async (body) => {
  const result = await postApi(`${baseUrl}/api/numberpdtype`, "get", body);
  return result;
});
export const numberPdGameHistory = createAsyncThunk("numberPdGameHistory", async (apiparms) => {
  const result = await postApi(`${baseUrl}/api/gamehistory/numberpd/${apiparms?.userid}/${apiparms?.gameHistoryid}`, "get",);
  return result;
});
//Categories
export const getCategories = createAsyncThunk("getCategories", async (body) => {
  const result = await postApi(`${baseUrl}/api/tambolaGameHistory/categories`, "get", body);
  return result;
});
// Amount Mgt
export const getAmount = createAsyncThunk("getAmount", async (body) => {
  const result = await postApi(`${baseUrl}/api/amountoption`, "get", body);
  return result;
});
export const updateAmount = createAsyncThunk("updateAmount", async (body) => {
  const result = await postApi(`${baseUrl}/api/amountoption/${body?.id}`,"put",body);
  await messages(result?.message, result?.status);
  return result;
});
//Users
export const getUsers = createAsyncThunk("getUsers", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/byrole`, "post", body);
  return result;
});
export const getSubAdmin = createAsyncThunk("getSubAdmin", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/subadminlist`, "post", body);
  return result;
});
export const getOptionSubAdmin = createAsyncThunk("getOptionSubAdmin", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/subadminoption`, "post", body);
  return result;
});
export const addSubadmin = createAsyncThunk("addSubadmin", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/subadmin`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getAgents = createAsyncThunk("getAgents", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/agentlist`, "post", body);
  return result;
});
export const addAgent = createAsyncThunk("addAgent", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/agent`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const logOutUser = createAsyncThunk("logOutUser", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/logout/user`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getOTP = createAsyncThunk("getOTP", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/getotp`, "post", body);
  return result;
});
export const getUserDetails = createAsyncThunk("getUserDetails", async (id) => {
  const result = await postApi(`${baseUrl}/api/users/${id}`, "get");
  return result;
});
export const updateUserDetails = createAsyncThunk("updateUserDetails", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/${body?.id}`,"put", body);
  await messages(result?.message, result?.status);
  return result;
});
//Wallet
export const addWallet = createAsyncThunk("addWallet", async (body) => {
  const result = await postApi(`${baseUrl}/api/transactions/wallet`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const resetLockedMoney = createAsyncThunk("resetLockedMoney", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/resetlockedmoney`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
//User Payment
export const getUserPayment = createAsyncThunk("getUserPayment", async (body) => {
  const result = await postApi(`${baseUrl}/api/payment/filter`, "post", body);
  return result;
});
export const updateUserPayment = createAsyncThunk("updateUserPayment", async (body) => {
  const result = await postApi(`${baseUrl}/api/payment/update/${body?.id}`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const InActiveUsers = createAsyncThunk("InActiveUsers", async (id) => {
  const result = await postApi(`${baseUrl}/api/users/inactive/${id}`, "get");
  return result;
});
//Withdrawal
export const getWithdrawal = createAsyncThunk("getWithdrawal", async (body) => {
  const result = await postApi(`${baseUrl}/api/payment/list_withdraw`, "post", body);
  return result;
});
export const updateWithdrawal = createAsyncThunk("updateWithdrawal", async (body) => {
  const result = await postApi(`${baseUrl}/api/payment/update/${body?.transaction_id}`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
//Transactions
export const getTransactions = createAsyncThunk("getTransactions", async (body) => {
  const result = await postApi(`${baseUrl}/api/transactions/filter`, "post", body);
  return result;
});
export const getUserTransactions = createAsyncThunk("getUserTransactions", async (body) => {
  const result = await postApi(`${baseUrl}/api/transactions`, "post", body);
  return result;
});
//Locked Device
export const getLockedDevices = createAsyncThunk("getLockedDevices", async (body) => {
  const result = await postApi(`${baseUrl}/api/lockeddevices`, "post", body);
  return result;
});
export const deleteLockedDevice = createAsyncThunk("deleteLockedDevice", async (data) => {
  const result = await postApi(`${baseUrl}/api/lockeddevices/${data?.id}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
//GST
export const getGST = createAsyncThunk("getGST", async (body) => {
  const result = await postApi(`${baseUrl}/api/gst/filter`, "post", body);
  return result;
});
//TDS
export const getTDS = createAsyncThunk("getTDS", async (body) => {
  const result = await postApi(`${baseUrl}/api/tds/filter`, "post", body);
  return result;
});
//KYC
export const getKYC = createAsyncThunk("getKYC", async (body) => {
  const result = await postApi(`${baseUrl}/api/kyc/filter`, "post", body);
  return result;
});
export const updateKYC = createAsyncThunk("updateKYC", async (body) => {
  const result = await postApi(`${baseUrl}/api/kyc/${body?.id}`,"put",body);
  await messages(result?.message, result?.status);
  return result;
});
//Settings
export const getSettings = createAsyncThunk("getSettings", async (body) => {
  const result = await postApi(`${baseUrl}/api/settings/tambola-game-setting`, "get", body);
  return result;
});
export const updateSetting = createAsyncThunk("updateSetting", async (body) => {
  const result = await postApi(`${baseUrl}/api/settings/${body?.id}`,"put",body);
  await messages(result?.message, result?.status);
  return result;
});
export const getSubadminSettings = createAsyncThunk("getSubadminSettings", async (body) => {
  const result = await postApi(`${baseUrl}/api/settings/subadmin-setting`, "get", body);
  return result;
});
//Profile
export const getProfile = createAsyncThunk("getProfile", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/me`, "get", body);
  return result;
});
export const updatePassword = createAsyncThunk("updatePassword", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/change-password`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
//signup
export const userSignup = createAsyncThunk("userSignup", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/signup`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
// Login
export const adminLogin = createAsyncThunk("adminLogin", async (body) => {
  const result = await postApi(`${baseUrl}/auth/login`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const loginbyother = createAsyncThunk("loginbyother", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/loginbyother`, "post", body);
  return result;
});
export const adminSelf = createAsyncThunk("adminSelf", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/adminself`, "post", body);
  return result;
});
export const userLogOut = createAsyncThunk("userLogOut", async (body) => {
  const result = await postApi(`${baseUrl}/auth/logout`, "get", body);
  return result;
});
//contact mail send
export const contactSendMail = createAsyncThunk("contactSendMail", async (body) => {
  const result = await postApi(`${baseUrl}/api/sendemail`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
//Subscription Plan
export const getSubscriptionPlan = createAsyncThunk("getSubscriptionPlan", async (body) => {
  const result = await postApi(`${baseUrl}/api/plan`, "get", body);
  return result;
});
export const planSubscribe = createAsyncThunk("planSubscribe", async (body) => {
  const result = await postApi(`${baseUrl}/api/subscription`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const subscriptionResponse = createAsyncThunk("subscriptionResponse", async (body) => {
  const result = await postApi(`${baseUrl}/api/subscription/response`, "post", body);
  return result;
});
export const subscriptionPayments = createAsyncThunk("subscriptionPayments", async (body) => {
  const result = await postApi(`${baseUrl}/api/subscription/filter`, "post", body);
  return result;
});
//User payment
export const userPayments = createAsyncThunk("userPayments", async (body) => {
  const result = await postApi(`${baseUrl}/api/payment`, "post", body);
  return result;
});
export const userPaymentResponse = createAsyncThunk("userPaymentResponse", async (body) => {
  const result = await postApi(`${baseUrl}/api/payment/response`, "post", body);
  return result;
});
//reset data
export const restRecord = createAsyncThunk("restRecord", async (data) => {
  return [];
});

const pagination = async (record, payload) => {
  const newList = Array.isArray(payload) ? payload : [];
  
  if (newList.length > 0) {
      record.push(...newList);
  }
};

const userReducer = createReducer(
  {},
  (builder) => {
    builder
      //reset data
      .addCase(restRecord.fulfilled, (state,) => {
        state.getSubAdminList = [];
        state.getUserPaymentList = [];
        state.getAgentsList = [];
        state.getUsersList = [];
        state.getTransactionsList = [];
        state.tambolaBookingList = [];
        state.getGSTList = [];
        state.getTDSList = [];
        state.getKYCList = [];
        state.inActiveUsersList = [];
        state.getWithdrawalList = [];
        state.subscriptionPaymentList = [];
        state.gameHistoryList = [];
        state.getUserTransactionList = [];
        state.colorGameHistoryList = [];
        state.redBlackGameList = [];
      })
      // Login
      .addCase(adminLogin.fulfilled, (state, action) => {
        if (action.payload.status === 1) {
          localStorage.setItem("x-auth-token", action.payload.token);
          localStorage.setItem("userRole", action.payload?.me?.role?.id);
          localStorage.setItem("username", action.payload?.me?.username);
          localStorage.setItem("userid", action.payload?.me?.id);
          localStorage.setItem("website", action.payload?.me?.website);
          localStorage.setItem("appSlug", action.payload?.me?.appSlug);
          localStorage.setItem("games", action.payload?.me?.games);
          localStorage.setItem("adminToken", action.payload.token);
        }
      })
      //other login loginbyother
      .addCase(loginbyother.fulfilled, (state, action) => {
        if (action.payload.status === 1) {
          localStorage.setItem("x-auth-token", action.payload?.data?.token);
          localStorage.setItem("userRole", action.payload?.data?.role?.id);
          localStorage.setItem("userid", action.payload?.data?.id);
          localStorage.setItem("username", action.payload?.data?.username);
          localStorage.setItem("website", action.payload?.data?.website);
          localStorage.setItem("appSlug", action.payload?.data?.appSlug);
          localStorage.setItem("games", action.payload?.data?.games);
          localStorage.setItem("goto", "admin");
        }
      })
      //admin Self
      .addCase(adminSelf.fulfilled, (state, action) => {
        if (action.payload.status === 1) {
          localStorage.setItem("x-auth-token", action.payload?.data?.token);
          localStorage.setItem("userRole", action.payload?.data?.role?.id);
          localStorage.setItem("website", action.payload?.data?.website);
          localStorage.setItem("appSlug", action.payload?.data?.appSlug);
          localStorage.setItem("goto", "super");
        }
      })
      // Dashboard
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.getDashboardData = action.payload?.data;
      })
      //Color Game
      .addCase(colorGameHistory.fulfilled, (state, action) => {
        if (!Array.isArray(state.colorGameHistoryList)) {
          state.colorGameHistoryList = [];
        }
        pagination(state.colorGameHistoryList, action.payload?.lastGamesReslt)
        state.colorGameHistoryBlank = action.payload?.lastGamesReslt;
      })
      .addCase(colorCurrentGame.fulfilled, (state, action) => {
        state.colorCurrentGameList = action.payload;
      })
      //Red & Black Game 
      .addCase(redBlackGameHistory.fulfilled, (state, action) => {
        if (!Array.isArray(state.redBlackGameList)) {
          state.redBlackGameList = [];
        }
        pagination(state.redBlackGameList, action.payload?.lastGamesReslt)
        state.redBlackHistoryBlank = action.payload?.lastGamesReslt;
      })
      .addCase(redBlackCurrentGame.fulfilled, (state, action) => {
        state.redBlackCurrentList = action.payload;
      })
      // Game list 
      .addCase(gameHistory.fulfilled, (state, action) => {
        if (!Array.isArray(state.gameHistoryList)) {
          state.gameHistoryList = [];
        }
        pagination(state.gameHistoryList, action.payload?.his)
        state.gameHistoryBlank = action.payload?.his;
        state.gameHistoryDetails = action.payload?.totalQuery;
      })
      .addCase(getUserGameHistory.fulfilled, (state, action) => {
        state.userGameHistoryList = action.payload;
      })
      .addCase(getGameTransactions.fulfilled, (state, action) => {
        state.getGameTransactionsList = action.payload?.transactions;
      })
      .addCase(getGames.fulfilled, (state, action) => {
        state.getGamesList = action.payload?.games;
      })
      .addCase(upcommingGames.fulfilled, (state, action) => {
        state.upcommingGameList = action.payload?.gameList;
      })
      //payment option 
      .addCase(getPaymentOptions.fulfilled, (state, action) => {
        state.paymentOptions = action.payload?.data;
      })
      .addCase(getPayOut.fulfilled, (state, action) => {
        state.payOutList = action.payload?.data;
      })
      //add tambola game by admin
      .addCase(addTambolaGameByAdmin.pending, (state, action) => {
        state.addTambolaByAdminWaiting = 0;
      })
      .addCase(addTambolaGameByAdmin.fulfilled, (state, action) => {
        state.addTambolaByAdminWaiting = 1;
      })
      //add tambola game by subadmin 
      .addCase(addTambolaGameBySubAdmin.pending, (state, action) => {
        state.addTambolaBySubAdminWaiting = 0;
      })
      .addCase(addTambolaGameBySubAdmin.fulfilled, (state, action) => {
        state.addTambolaBySubAdminWaiting = 1;
      })
      //tambola furures game
      .addCase(tambolaFutureGames.fulfilled, (state, action) => {
        state.tambolaFutureGamesList = action.payload?.gameList;
      })
      .addCase(tambolaGameByID.fulfilled, (state, action) => {
        state.tambolaGameByIdData = action.payload?.singleGame;
      })
      .addCase(tambolaBooking.fulfilled, (state, action) => {
        if (!Array.isArray(state.tambolaBookingList)) {
          state.tambolaBookingList = [];
        }
        pagination(state.tambolaBookingList, action.payload?.bets)
        state.tambolaBookingBlank = action.payload?.bets;
      })
      .addCase(tambolaGameHisTicketByGame.fulfilled, (state, action) => {
        state.tambolaGameHisTicketByGameList = action.payload?.allTickets;
      })
      //Bots
      .addCase(getBoats.fulfilled, (state, action) => {
        state.getBoatList = action.payload?.boats;
      })
      //Number PD 
      .addCase(numberPdGame.fulfilled, (state, action) => {
        state.numberPdBettings = action.payload?.bettings;
        state.numberPdCurrentGame = action.payload?.currentGame;
        state.numberPdSpotWithBetTotalAmount = action.payload?.spotWithBetTotalAmount;
        state.numberPdUserWithBetTotalAmount = action.payload?.userWithBetTotalAmount;
      })
      .addCase(getNumberpdType.fulfilled, (state, action) => {
        state.getNumberpdTypeList = action.payload?.numberTypes;
      })
      .addCase(numberPdGameHistory.fulfilled, (state, action) => {
        state.numberPdGameBettings = action.payload?.bettings;
      })
      //Reward 
      .addCase(getTambolaReward.fulfilled, (state, action) => {
        state.getTambolaRewardList = action.payload?.tambolaRewards;
      })
      //Categories 
      .addCase(getCategories.fulfilled, (state, action) => {
        state.getCategoriesList = action.payload?.types;
      })
      //Amount list
      .addCase(getAmount.fulfilled, (state, action) => {
        state.getAmountList = action.payload?.amounts;
      })
      //Users
      .addCase(getUsers.fulfilled, (state, action) => {
        if (!Array.isArray(state.getUsersList)) {
          state.getUsersList = [];
        }
        pagination(state.getUsersList, action.payload?.users)
        state.getUsersBlank = action.payload?.users;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.getUserDetail = action.payload?.user;
      })
      .addCase(getOTP.fulfilled, (state, action) => {
        state.userOTP = action.payload?.otp;
      })
      //get subadmin 
      .addCase(getSubAdmin.fulfilled, (state, action) => {
        if (!Array.isArray(state.getSubAdminList)) {
          state.getSubAdminList = [];
        }
        pagination(state.getSubAdminList, action.payload?.users)
        state.subadminBlank = action.payload?.users;
      })
      .addCase(getOptionSubAdmin.fulfilled, (state, action) => {
        state.getOptionSubAdminList = action.payload?.users;
      })
      .addCase(getAgents.fulfilled, (state, action) => {
        if (!Array.isArray(state.getAgentsList)) {
            state.getAgentsList = [];
        }
        pagination(state.getAgentsList, action.payload?.users);
        state.getAgentsBlank = action.payload?.users;
      })
      //User Payment
      .addCase(getUserPayment.fulfilled, (state, action) => {
        if (!Array.isArray(state.getUserPaymentList)) {
          state.getUserPaymentList = [];
        }
        pagination(state.getUserPaymentList, action.payload?.payments);
        state.getUserPaymentBlank = action.payload?.payments;
        state.totalAmount = action.payload.totalAmount;
        state.transfertime = action.payload.dailytransfertime;
      })
      .addCase(InActiveUsers.fulfilled, (state, action) => {
        if (!Array.isArray(state.inActiveUsersList)) {
          state.inActiveUsersList = [];
        }
        pagination(state.inActiveUsersList, action.payload);
        state.inActiveUsersBlank = action.payload ;
      })
      // Withdrawal 
      .addCase(getWithdrawal.fulfilled, (state, action) => {
        if (!Array.isArray(state.getWithdrawalList)) {
          state.getWithdrawalList = [];
        }
        pagination(state.getWithdrawalList, action.payload?.payments);
        state.getUserPaymentBlank = action.payload?.payments;
        state.totalAmount = action.payload.totalAmount;
      })
      // Transactions 
      .addCase(getTransactions.fulfilled, (state, action) => {
        if (!Array.isArray(state.getTransactionsList)) {
          state.getTransactionsList = [];
        }
        pagination(state.getTransactionsList, action.payload?.payments);
        state.getTransactionsBlank = action.payload?.data;
      })

      // .addCase(getUserTransactions.fulfilled, (state, action) => {
      //   state.getUserTransactionList = action.payload;
      // })
      
      .addCase(getUserTransactions.fulfilled, (state, action) => {
        if (!Array.isArray(state.getUserTransactionList)) {
          state.getUserTransactionList = [];
        }
        pagination(state.getUserTransactionList, action.payload);
        state.getUserTransactionsBlank = action.payload;
      })
      //Locaked Device
      .addCase(getLockedDevices.fulfilled, (state, action) => {
        state.getLockedDevicesList = action.payload?.lockedDevices;
      })
      // GST 
      .addCase(getGST.fulfilled, (state, action) => {
        if (!Array.isArray(state.getGSTList)) {
          state.getGSTList = [];
        }
        pagination(state.getGSTList, action.payload?.list);
        state.getGSTBlank = action.payload?.list;
      })
      // TDS 
      .addCase(getTDS.fulfilled, (state, action) => {
        if (!Array.isArray(state.getTDSList)) {
          state.getTDSList = [];
        }
        pagination(state.getTDSList, action.payload?.list);
        state.getTDSBlank = action.payload?.list;
      })
      // KYC 
      .addCase(getKYC.fulfilled, (state, action) => {
        if (!Array.isArray(state.getKYCList)) {
          state.getKYCList = [];
        }
        pagination(state.getKYCList, action.payload?.list);
        state.getKYCBlank = action.payload?.list;
      })
      //Settings
      .addCase(getSettings.fulfilled, (state, action) => {
        state.getSettingList = action.payload?.settings;
      })
      .addCase(getSubadminSettings.fulfilled, (state, action) => {
        state.getSubadminSettingsList = action.payload?.settings;
      })
      //Profile
      .addCase(getProfile.fulfilled, (state, action) => {
        state.getProfileDetails = action.payload?.me;
      })
      //subscription plan 
      .addCase(getSubscriptionPlan.fulfilled, (state, action) => {
        state.getSubscriptionPlanList = action.payload?.plans;
      })
      .addCase(planSubscribe.fulfilled, (state, action) => {
        state.planSubscribeDetails = action.payload?.data;
      })
      .addCase(subscriptionResponse.fulfilled, (state, action) => {
        state.subscriptionResponseDetails = action.payload;
        state.subscriptionUser = action.payload?.data;
      })
      .addCase(subscriptionPayments.fulfilled, (state, action) => {
        if (!Array.isArray(state.subscriptionPaymentList)) {
          state.subscriptionPaymentList = [];
        }
        pagination(state.subscriptionPaymentList, action.payload?.payments);
        state.subscriptionPaymentBlank = action.payload?.payments;
      })
      //UserPayments 
      .addCase(userPayments.fulfilled, (state, action) => {
        state.userPaymentsDetails = action.payload?.data;
      })
      .addCase(userPaymentResponse.fulfilled, (state, action) => {
        state.userPaymentsRespmsg = action.payload?.data?.message;
        state.userPaymentsRespStatus = action.payload?.data?.txn_status;
      })
      
  }
);

export default userReducer;
