import React from "react";
import "../app.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { userSignup } from "../reducers/commonReducer";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";

// Validation by Yup
const schema = yup.object().shape({
  tel: yup
    .string()
    .required("Enter Phone Number")
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Phone number is not valid"),
  pwd: yup.string().required("Enter your Password."),
  confirmPwd: yup
    .string()
    .oneOf([yup.ref("pwd"), null], "Passwords must match")
    .required("Confirm your Password."),
  acceptTerms: yup
    .bool()
    .oneOf([true], "You must accept the privacy policy.")
    .required("You must accept the privacy policy."),
});

// Login Page
const Signup = () => {
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const code = queryParameters.get("code");
  let width = window.innerWidth;
  let dateYear = new Date();

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "https://rover4x4.in/rover4x4.apk";
    link.download = "filename"; // Optional: you can specify a default filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="">
        <div className="row bg pt-3 pb-3">
          <Col
            md={12}
            className={`p-5 loginPanel ${width > 500 ? "w-40" : "w-100"}`}
          >
            <div className="form-logo text-center">
              <img className="rounded" src={logo} alt="logo" />
            </div>
            {/* <div className="text-center mt-4">
              <h4>
                Welcome <span className="back-color"> Back !</span>
              </h4>
            </div> */}
            <div className="text-center">
              <p>Register your Phone<i className="bi bi-phone"></i></p>
            </div>

            <Formik
              validationSchema={schema}
              onSubmit={async (values, { resetForm }) => {
                const apiResp = await dispatch(userSignup(values));
                if (apiResp?.payload?.status) {
                  resetForm({ values: "" });
                  handleDownload();
                }
              }}
              initialValues={{
                tel: "",
                pwd: "",
                code,
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="col-12 mt-5">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-person-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          id="tel"
                          name="tel"
                          value={values.tel}
                          onChange={handleChange}
                          isInvalid={!!errors.tel}
                          className="form-control"
                          placeholder="Enter Phone"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.tel}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </div>

                  <div className="col-12 mt-4">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-lock-fill"></i>
                        </div>
                        <Form.Control
                          type="password"
                          name="pwd"
                          id="pwd"
                          value={values.pwd}
                          onChange={handleChange}
                          isInvalid={!!errors.pwd}
                          className="form-control"
                          placeholder="Enter Password"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.pwd}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </div>

                  <div className="col-12 mt-4">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-lock-fill"></i>
                        </div>
                        <Form.Control
                          type="password"
                          name="confirmPwd"
                          id="confirmPwd"
                          value={values.confirmPwd}
                          onChange={handleChange}
                          isInvalid={!!errors.confirmPwd}
                          className="form-control"
                          placeholder="Confirm Password"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.confirmPwd}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </div>

                  <div className="col-12 mt-4">
                    <Form.Group as={Col} md="12">
                      <div className="form-check">
                        <Form.Check
                          type="checkbox"
                          name="acceptTerms"
                          id="acceptTerms"
                          checked={values.acceptTerms}
                          onChange={(e) => setFieldValue("acceptTerms", e.target.checked)}
                          isInvalid={!!errors.acceptTerms}
                          feedback={errors.acceptTerms}
                          feedbackType="invalid"
                          required
                        />
                        <label htmlFor="acceptTerms" className="form-check-label">
                          I have read and agree to the{" "}
                          <Link to="https://rover4x4.in/privacy-policy.html">Privacy Agreement</Link>
                        </label>
                        {errors.acceptTerms && (
                          <div className="invalid-feedback">{errors.acceptTerms}</div>
                        )}
                      </div>
                    </Form.Group>
                  </div>


                  <div className="col-12 text-center mt-4">
                    <Button
                      type="submit"
                      className="button-color login-btn px-4 w-100"
                    >
                      Register
                    </Button>
                  </div>

                  <div className="col-12 text-center mt-4">
                    <p>
                      All Rights Reserved by 💗 Rover 4x4 ©{" "}
                      {dateYear.getFullYear()}
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </div>
      </div>
    </>
  );
};

export default Signup;
