import React from "react";
import "../app.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { adminLogin } from "../reducers/commonReducer";
import logo from "../images/logo.png";

// Validation by Yup
const schema = yup.object().shape({
  username: yup.string().required("Enter UserName"),
  password: yup.string().required("Enter your Password."),
});

// Login Page
const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let width = window.innerWidth;
  
  let dateYear = new Date();
  const login = async (values) => {
    const apiResponse = await dispatch(adminLogin(values));
    if (apiResponse?.payload?.status === 1 && localStorage.getItem("x-auth-token") ) {
    return setTimeout(() => {
        navigate(
          (((localStorage.getItem("userRole") === "1") ? "/sub-admin" : (localStorage.getItem("userRole") === "2") ? "/users" : (localStorage.getItem("userRole") === "5") ? "/tambola-future-games" : (localStorage.getItem("userRole") === "6") ? "/tambola-future-games" : '/')));
      }, 1000);
    }
    return null;
  };
  return (
    <>
      <div className="">
        <div className="row bg pt-3 pb-3">
          <Col md={12} className={`p-5 loginPanel ${width > 500 ? "w-40" : "w-100"}`}>
            <div className="form-logo text-center">
              <img className="rounded" src={logo}  alt="logo"/>
            </div>
            <div className="text-center mt-4">
              <h4>
                Welcome <span className="back-color"> Back !</span>
              </h4>
            </div>
            <div className="text-center">
              <p>Sign in to panel</p>
            </div>

            <Formik
              validationSchema={schema}
              onSubmit={(values, { resetForm }) => {
                login(values);
                resetForm({ values: "" });
              }}
              initialValues={{
                username: "",
                password: "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="col-12 mt-5">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-person-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          id="username"
                          name="username"
                          value={values.username}
                          onChange={handleChange}
                          isInvalid={!!errors.username}
                          className="form-control"
                          placeholder="UserName"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                        {errors.username}
                      </Form.Control.Feedback>
                      </div>
                      
                    </Form.Group>
                  </div>

                  <div className="col-12 mt-4">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-lock-fill"></i>
                        </div>
                        <Form.Control
                          type="password"
                          name="password"
                          id="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                          className="form-control"
                          placeholder="Password"
                          required
                        />
                         <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                      </div>
                     
                    </Form.Group>
                  </div>
                  <div className="col-12 text-center mt-4">
                    <Button
                      type="submit"
                      className="button-color login-btn px-4 w-100"
                    >
                      Log in
                    </Button>
                  </div>

                  <div className="col-12 text-center mt-4">
                    <p>
                      All Rights Reserved by 💗 Rover 4x4 ©{" "}
                      {dateYear.getFullYear()}
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
