import React, { useEffect, useState, useRef } from 'react';

const TimerSecondCounter = ({ startTime, endTime }) => {
  const [timeLeft, setTimeLeft] = useState(null);
  const timerIntervalRef = useRef(null);

  useEffect(() => {
    const end = new Date(endTime).getTime();

    const updateTimeLeft = () => {
      const now = Date.now();
      const timeLeftMs = end - now;

      if (timeLeftMs <= 0) {
        setTimeLeft(0);
        clearInterval(timerIntervalRef.current);
      } else {
        setTimeLeft(Math.floor(timeLeftMs / 1000));
      }
    };

    // Set initial time left
    updateTimeLeft();

    // Initialize the interval
    timerIntervalRef.current = setInterval(updateTimeLeft, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timerIntervalRef.current);
  }, [endTime]);

  return (
    <div>
      {timeLeft !== null ? (
        <p>
          <span className='counter-box px-3'>
            <b>{timeLeft ? timeLeft : 0}</b>
          </span>
        </p>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default TimerSecondCounter;
