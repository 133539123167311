import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import {Row, Table, Col, Form, Button} from "react-bootstrap";
import { addAgent, addWallet, getAgents, getUsers, logOutUser, restRecord, updateUserDetails } from "../../reducers/commonReducer";
import SideBaar from "../../Components/SideBaar";
import Profile from "./Profile";
import AmountAction from "./AmountAction";
import UserAction from "./UserAction";
import AutoFillDate from "../../AutoFillDate";
import moment from "moment";
 
const UserList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startDate, setstartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DDT00:00:00'));
  const [endDate, setendDate] = useState(moment().format('YYYY-MM-DDT23:59:59'));
  const [userStatus, setUserStatus] = useState();
  const [day, setDay] = useState();
  const [searchusername, setSearchusername] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const queryParameters = new URLSearchParams(window.location.search);
  const subadminId = queryParameters.get('subadminId');
  const userType = queryParameters.get('userType');

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(restRecord());
    ((userType === "1") || localStorage.getItem("website")) ?
    dispatch(getAgents(
      (localStorage.getItem("userRole") === "1") ? {
      subadmin:subadminId, end:endDate, start:startDate,
      } : {}
    )) :
    dispatch(getUsers( (localStorage.getItem("userRole") === "1") ? {
      subadmin:subadminId, end:endDate, start:startDate,
    } : {}
    ));
  }, [subadminId, userType, dispatch, navigate]);
  const { getUsersList, getUsersBlank, getAgentsList, getAgentsBlank } = useSelector((state) => state.commonReducer);
  const handleSubmit = (e) => {
    dispatch(restRecord());
    ((userType === "1") || localStorage.getItem("website")) ? dispatch(getAgents({
       end:endDate, start:startDate, userStatus, day, searchusername
    })) : dispatch(getUsers({
      end:endDate, start:startDate, userStatus, day, searchusername
   }))
  }

  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6}>
            <h3>Users</h3>
          </Col>
          <Col md={6}>
            {
               (localStorage.getItem("website")?.length > 0) && <UserAction type={"Add"} buttonFloat={"float-right"} btnTitle={"Add"} api={addAgent} userType={userType} subadminId={subadminId}/>
            }
          </Col>
        </Row>
        <hr className="m-0"/>
        <Row className="mb-2 user-table">
          <Col md={2}>
            <label>
              <b>Username</b>
            </label>
            <Form.Control
              type="text"
              placeholder="Enter Username"
              step="1"
              value={searchusername}
              onChange={(e) => setSearchusername(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <label>
              <b>Start Date</b>
            </label>
            <Form.Control
              type="date"
              placeholder="Enter Date"
              step="1"
              value={startDate}
              onChange={(e) => setstartDate(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <label>
              <b>End Date</b>
            </label>
            <Form.Control
              type="date"
              placeholder="Enter Date"
              step="1"
              value={endDate}
              onChange={(e) => setendDate(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <AutoFillDate setendDate={setendDate} setstartDate={setstartDate} day={day} setDay={setDay}/>
          </Col>
          <Col md={2}>
              <label>
                <b>Select User Status</b>
              </label>
              <Form.Select value={userStatus} onChange={(e)=> setUserStatus(e.target.value)}>
                  <option value="">Select Status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="prime">Prime</option>
              </Form.Select>
          </Col>
          <Col md={2}>
            <Button className="mt-4 w-50" variant="success" type="submit" onClick={handleSubmit}>
              Submit
            </Button>
            <Button className="mt-4 w-50" variant="danger" type="submit" onClick={() => window.location.reload(true)}>
              Clear
            </Button>
          </Col>
        </Row>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>UserName</th>
                <th>Activity</th>
                {((userType === "1") || localStorage.getItem("website")) && <th>Details</th>}
                <th>Points</th>
                <th>Mobile No.</th>
                <th>Trans</th>
                <th>Profit/Loss</th>
                <th>Wallet</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {(((userType === "1") || localStorage.getItem("website")?.length > 0) ? (!!getAgentsList ? getAgentsList : []) : (!!getUsersList ? getUsersList : []))?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>
                          <Profile
                            coins={data?.coins.toFixed(2)}
                            username={data?.username}
                            id={data?.id}
                            createdAt={data?.createdAt}
                            email={data?.email}
                            personName={data?.name}
                            phone={data?.phone}
                            role={data?.role?.name}
                            winLoss={(data?.winning - data?.betting_points)?.toFixed(2)}
                            appversion={data?.appversion}
                            device_id={data?.device_id}
                            onlineAmount={data?.cashflow?.online_add_coins}
                            withdraw={data?.cashflow?.withdraw}
                            bonus_coins={data?.cashflow?.bonus_coins}
                            os={data?.os}
                            offline_add_coins={data?.cashflow?.offline_add_coins}
                            customerIp={data?.customerIp}
                            deviceName={data?.deviceName}
                            prime={data?.prime}
                          />
                      </td>
                      <td>{(((new Date() - new Date(data?.updatedAt)) / 1000) > 300) ? <b className="text-danger">OFFLINE</b> : <b className="text-success">ONLINE</b>}</td>
                      {((userType === "1") || localStorage.getItem("website")) && <td><Button variant="info" onClick={() => navigate(`/game-tickets?userid=${data?.id}`)}>Ticket</Button></td>}
                      <td>{data?.coins?.toFixed(2)}</td>
                      <td>{data?.phone}</td>
                      <td>
                        <Link
                          to={`/user-transaction?userid=${data?.id}&username=${data?.username}&usertype=${data?.prime}`}
                          variant="info"
                        
                        >
                          <i className="bi bi-cash-coin"></i>
                        </Link>
                      </td>
                      <td>{(data?.winning - data?.betting_points)?.toFixed(1)}</td>
                      <td>
                        <AmountAction
                        type={"Add"}
                        user_id={data?.id}
                        username={data?.username}
                        api={addWallet}
                        />
                        {data?.username !== 'admin' && <AmountAction
                        type={"Withdrawal"}
                        user_id={data?.id}
                        username={data?.username}
                        api={addWallet}
                        coins={data?.coins}
                        lockedmoneyAmount={data?.lockedmoney}
                        />}
                      </td>
                      <td>
                        {data.status ? "Unblock" : "Block"}
                      </td>
                      <td>
                        <Profile
                          profileView={true}
                          coins={data?.coins.toFixed(2)}
                          username={data?.username}
                          id={data?.id}
                          createdAt={data?.createdAt}
                          email={data?.email}
                          personName={data?.name}
                          phone={data?.phone}
                          role={data?.role?.name}
                          winLoss={(data?.winning - data?.betting_points)?.toFixed(2)}
                          appversion={data?.appversion}
                          device_id={data?.device_id}
                          onlineAmount={data?.cashflow?.online_add_coins}
                          withdraw={data?.cashflow?.withdraw}
                          bonus_coins={data?.cashflow?.bonus_coins}
                          os={data?.os}
                          offline_add_coins={data?.cashflow?.offline_add_coins}
                          customerIp={data?.customerIp}
                          deviceName={data?.deviceName}
                          prime={data?.prime}
                        />
                        <UserAction
                          type={"Update"}
                          id={data?.id}
                          api={updateUserDetails}
                          status={data?.status}
                          commission={data?.commission}
                          email={data?.email}
                          name={data?.name}
                          password={data?.password}
                          phone={data?.phone}
                          role={data?.role?._id}
                          ifsc={data?.ifsc} 
                          accountName={data?.accountName} 
                          account={data?.account}
                          username={data?.username}/>
                        {
                          data?.username !== 'admin' && <Button className="m-1 py-1 px-2" onClick={() =>
                            dispatch(logOutUser({
                              userId : data?.id
                            }))
                          } variant="danger"><i className="bi bi-power"></i></Button>
                        }
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {
            ((userType === "1") || localStorage.getItem("website")) ? 
           ( !!getAgentsBlank && getAgentsBlank?.length < 1 ? <h3 className="text-center text-danger">Not Found</h3> : 
            <Button onClick={() => {
              dispatch(getAgents({currentPage:currentPage + 1, end:endDate, start:startDate, userStatus, day, searchusername}))
              setCurrentPage(currentPage + 1)
            }} className="w-100">Load More</Button>) :
            ( !!getUsersBlank && getUsersBlank?.length < 1 ? <h3 className="text-center text-danger">Not Found</h3> : 
            <Button onClick={() => {
              dispatch(getUsers({currentPage:currentPage + 1, end:endDate, start:startDate, userStatus, day, searchusername}))
              setCurrentPage(currentPage + 1)
            }} className="w-100">Load More</Button>)
          }
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default UserList;
